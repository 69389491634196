import ABSLogo from '@abs-warranty/next-components/abs-logo'
import formatPhone from '../../utils/format-phone'
import { faMarkdown } from '@abs-warranty/free-brands-svg-icons/faMarkdown'
import { faEnvelope } from '@abs-warranty/pro-regular-svg-icons/faEnvelope'
import { faPhone } from '@abs-warranty/pro-regular-svg-icons/faPhone'
import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import Link from 'next/link'
import React from 'react'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { useTheme } from '../../modules/theme'
import { useUser } from '../../modules/user'
import { sitemap } from '../../sitemap'
import UserPhoto from './user-photo'
import ModalSignOut from './modal-sign-out'
import ModalUserSettings from './modal-user-settings'
import SoftPhone from '../dashboard/soft-phone'
import { useRouter } from 'next/router'

/**
 * @param {object} props
 * @param {JSX.Element} [props.links] `Link` elements to display on the `Navbar`
 * @param {boolean} [props.popup] `true` when window is a minimal popup - links are disabled or open in a new tab
 * @returns {JSX.Element}
 */
function TopNavbar({ links, popup = false }) {
  const { user, isUserLoading, isUserError, reload } = useUser()
  const { isThemeDark } = useTheme()
  const router = useRouter()

  if (isUserLoading) return <></>

  // in Azure AD, phones are formatted like 555-123-4567
  const userPhone = user.profile?.businessPhones?.length
    ? formatPhone('+1' + user.profile.businessPhones[0].replaceAll('-', ''))
    : ''

  // pages
  const admin = sitemap.get('admin')
  const reports = sitemap.get('reports')
  const dataIngestion = sitemap.get('dataIngestion')

  return (
    <Navbar
      variant={isThemeDark ? 'dark' : 'light'}
      bg={isThemeDark ? 'dark' : 'white'}
      expand="lg"
      sticky="top"
      collapseOnSelect
      className="d-print-none border-bottom shadow-sm"
    >
      <Container fluid>
        {popup ? (
          <Navbar.Brand className="p-0">
            <ABSLogo height={60} dark={isThemeDark} />
          </Navbar.Brand>
        ) : (
          <Link href="/">
            <Navbar.Brand className="p-0">
              <ABSLogo height={60} dark={isThemeDark} />
            </Navbar.Brand>
          </Link>
        )}
        {!isUserError && (
          <>
            <Navbar.Toggle aria-controls="header-nav" />
            <Navbar.Collapse>
              <Nav>{links}</Nav>
              {/* TODO: focus softphone if the window already exists rather than reloading the window */}
              {/* Reloading the window causes the user's state to switch to offline; phone call may disconnect */}
              {/* <Nav className="ms-auto">
                {user?.isPhone && (
                  <div className="d-block position-relative">
                    <SoftPhone btnOnly />
                  </div>
                )}
              </Nav> */}
              <Nav className="ms-auto">
                <NavDropdown
                  align="end"
                  renderMenuOnMount
                  title={
                    <div className="d-flex align-items-center">
                      <span className="me-2">{user.profile?.givenName ?? user.name?.split(' ')?.[0]}</span>
                      <UserPhoto />
                    </div>
                  }
                >
                  <NavDropdown.Item disabled>
                    <span className="fw-bold fs-5">{user.profile?.displayName ?? user.name}</span>
                    <p className="fst-italic fw-normal my-0 small" hidden={!user.profile?.jobTitle}>
                      {user.profile?.jobTitle}
                    </p>
                  </NavDropdown.Item>
                  <NavDropdown.Item disabled hidden={!userPhone} className="small">
                    <FontAwesomeIcon icon={faPhone} fixedWidth className="me-2" />
                    {formatPhone(userPhone)}
                  </NavDropdown.Item>

                  <NavDropdown.Item disabled className="small">
                    <FontAwesomeIcon icon={faEnvelope} fixedWidth className="me-2" />
                    {user.profile?.mail ?? user.email}
                  </NavDropdown.Item>

                  <NavDropdown.Divider />

                  {/* This conflicts with the user settings form in Portal Admin, so disable it as a quick fix */}
                  {!router.asPath.match(/\/admin\/general\/users\/[a-f0-9]+\/edit/) && (
                    <>
                      <ModalUserSettings user={user} reload={reload} />
                      {(user.isAdmin || user.isReporter) && !popup && <NavDropdown.Divider />}
                    </>
                  )}

                  {user.isAdmin && !popup && (
                    <Link href={admin?.href ?? ''} className="dropdown-item">
                      {admin?.icon && <FontAwesomeIcon icon={admin.icon} fixedWidth className="me-2" />}
                      {admin?.label}
                    </Link>
                  )}

                  {user.isReporter && !popup && (
                    <>
                      <Link href={reports?.href ?? ''} className="dropdown-item">
                        {reports?.icon && <FontAwesomeIcon icon={reports.icon} fixedWidth className="me-2" />}
                        {reports?.label}
                      </Link>
                      <Link href={dataIngestion?.href ?? ''} className="dropdown-item">
                        {dataIngestion?.icon && (
                          <FontAwesomeIcon icon={dataIngestion.icon} fixedWidth className="me-2" />
                        )}
                        {dataIngestion?.label}
                      </Link>
                    </>
                  )}

                  {!popup && (
                    <>
                      <NavDropdown.Divider />
                      <NavDropdown.Header>Help</NavDropdown.Header>
                      <Link href="/help/markdown-guide" className="dropdown-item">
                        <FontAwesomeIcon icon={faMarkdown} fixedWidth className="me-2" />
                        Markdown guide
                      </Link>
                    </>
                  )}

                  <NavDropdown.Divider />
                  <ModalSignOut />
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  )
}

export default TopNavbar
