import React from 'react'
import { FontAwesomeIcon } from '@abs-warranty/react-fontawesome'
import { faUserHeadset } from '@abs-warranty/pro-light-svg-icons/faUserHeadset'
import { faPhoneOffice } from '@abs-warranty/pro-light-svg-icons/faPhoneOffice'
import { faListRadio } from '@abs-warranty/pro-light-svg-icons/faListRadio'
import { faClockRotateLeft } from '@abs-warranty/pro-light-svg-icons/faClockRotateLeft'
import { Alert } from 'react-bootstrap'
import { useUser } from '../../modules/user'
import { useRouter } from 'next/router'
import Link from 'next/link'

/**
 * @param {object} props
 * @param {boolean} [props.btnOnly] returns the button without the surrounding alert
 * @returns {JSX.Element}
 */
function SoftPhone({ btnOnly = false }) {
  const { isUserLoading, user } = useUser()
  if (isUserLoading || !user?.isPhone) return <></>

  return btnOnly ? (
    <SoftPhoneButton />
  ) : (
    <div className="component--launch-soft-phone">
      <Alert variant="primary" className="pb-1 m-0">
        <Alert.Heading className="h5 fw-bold d-flex align-items-center">
          <FontAwesomeIcon icon={faUserHeadset} className="me-2 mb-0 h3" />
          <span>Call Center</span>
        </Alert.Heading>
        <div className="d-flex justify-content-start">
          <SoftPhoneButton />
          <Link href="/call-queue" className="btn btn-primary btn-lg shadow-sm my-2">
            Call Queue
            <FontAwesomeIcon icon={faListRadio} className="ms-2" />
          </Link>
          <Link href="/call-history" className="btn btn-outline-secondary btn-lg shadow-sm my-2 ms-auto">
            Call History
            <FontAwesomeIcon icon={faClockRotateLeft} className="ms-2" />
          </Link>
        </div>
      </Alert>
    </div>
  )
}

/** @returns {JSX.Element} */
function SoftPhoneButton() {
  const router = useRouter()
  // don't show the button if this is the phone page
  return router.pathname === '/phone' ? (
    <></>
  ) : (
    <button
      type="button"
      className="btn btn-sunset text-dark btn-lg shadow-sm my-2 me-3"
      onClick={() => {
        const width = 1400
        const height = 1200
        const left = (screen.width - width) / 2
        const top = (screen.height - height) / 4
        // @ts-ignore: window.absSoftPhone exists now, literally setting it right here
        window.absSoftPhone = window.open(
          '/phone',
          'softphone',
          `popup=true,width=${width},height=${height},left=${left},top=${top}`
        )
      }}
    >
      Phone
      <FontAwesomeIcon icon={faPhoneOffice} className="ms-2" />
    </button>
  )
}

export default SoftPhone
